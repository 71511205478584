import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Attachment } from '@tag/graphql';
import { map, take } from 'rxjs';
import { BaseSignalStateService } from './base-signal-state.service';
import { AttachmentService } from '@shared-components/import-attachment/attachment.service';
import { WorkOrderDocumentType } from '@api/types';
import { AttachmentsGQL } from '@shared/apollo/queries/attachment';
import { environment } from '@environments/environment';
import { toObservable } from '@angular/core/rxjs-interop';

export interface AttachmentPayload {
  Equipment?: {
    ID: string;
  }[];

  Work_Orders?: {
    Document_Type: string;
    No: string;
  }[];

  Work_Order_Lines?: {
    Document_Type: string;
    No: string;
    Line_No: number;
  };

  Requests?: {
    No: string;
  }[];

  Purchase_Orders?: {
    No: string;
  }[];

  DMP?: {
    No: string;
  }[];
}

export interface AttachmentState {
  documentId: string;
  attachments: Attachment[];
}
[];

const baseWs = environment.baseWS;

@Injectable({
  providedIn: 'root',
})
export class AttachmentsStateService extends BaseSignalStateService<AttachmentState> {
  readonly state$ = toObservable(this.state);
  constructor(
    private http: HttpClient,
    private attachmentsGQL: AttachmentsGQL,
    private attachmentService: AttachmentService
  ) {
    super();
  }

  refreshDocument(documentId: string) {
    const state = this.state();
    const index = state.findIndex((s) => s.documentId === documentId);
    if (index !== -1) {
      state.splice(index, 1);
      this.state.set(state);
    }
  }

  getDocumentAttachments(
    type: keyof AttachmentPayload,
    no: string,
    id: string,
    lineNo = 0,
    documentType?: WorkOrderDocumentType
  ) {
    const documentId = this.generateDocumentId(type, no, lineNo, documentType);

    const payload: AttachmentPayload = {
      [type]: [
        {
          Document_Type: documentType,
          Line_No: lineNo,
          No: no,
          ID: id,
        },
      ],
    };

    if (this.state().some((s) => s.documentId === documentId)) {
      return;
    }

    if (type === 'Purchase_Orders') {
      this.attachmentsGQL
        .watch({
          filter: `Source_Type eq 'PurchaseOrder' and No eq '${no}'`,
        })
        .valueChanges.pipe(take(1))
        .subscribe((result) => {
          const attachments = result.data.attachments.items;
          const currentState = this.state();
          let newState;

          const existingIndex = currentState.findIndex(
            (s) => s.documentId === documentId
          );
          if (existingIndex !== -1) {
            // Update existing entry: create a new array with the updated entry
            newState = currentState.map((item, index) =>
              index === existingIndex
                ? { ...item, attachments: attachments }
                : item
            );
          } else {
            // Add new entry: create a new array with the new entry added
            newState = [
              ...currentState,
              { documentId: documentId, attachments: attachments },
            ];
          }

          this.state.set(newState as AttachmentState[]);
        });

      return;
    }

    this.http
      .post<Attachment[]>(`${baseWs}/attachments/related`, payload)
      .pipe(
        take(1),
        map((attachments) =>
          this.attachmentService.mapApiToTagModel(attachments)
        )
      )
      .subscribe((attachments) => {
        const currentState = this.state();
        let newState;

        const existingIndex = currentState.findIndex(
          (s) => s.documentId === documentId
        );
        if (existingIndex !== -1) {
          // Update existing entry: create a new array with the updated entry
          newState = currentState.map((item, index) =>
            index === existingIndex
              ? { ...item, attachments: attachments }
              : item
          );
        } else {
          // Add new entry: create a new array with the new entry added
          newState = [
            ...currentState,
            { documentId: documentId, attachments: attachments },
          ];
        }

        this.state.set(newState as AttachmentState[]);
      });
  }

  generateDocumentId(
    type: keyof AttachmentPayload,
    no: string,
    lineNo = 0,
    documentType?: WorkOrderDocumentType
  ) {
    return `${type}_${no}_${lineNo}_${documentType}`;
  }
}
